import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import Media from './../theme/media-breackpoint';
import MainBG from '../assets/images/main-bg.png'; 

var Gs = {}
 
Gs.GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0; 
    font-family: 'IBM Plex Mono', monospace; 
    font-weight:400 ;
    background: url(${MainBG}) top left no-repeat ${(props) => props.theme.BG1};
    color:${(props) => props.theme.Color1};
  }  
  button{ outline:none; border:none;}
  img{max-width:100%;}
  .bodySection{ display: flex; align-items: flex-start; justify-content: flex-start; flex-wrap:wrap;} 

  .aos-init {
    ${Media.sm} {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;

    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;

    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
    }
  }
`; 

 

Gs.Container = styled.div`
  margin:0 auto; width: 100%; max-width:1360px; padding:0 15px;
  ${Media.lg} {
    max-width:1276px;
  }
  ${Media.lg2} {
    max-width:1170px;
  }
  ${Media.md2} {
    max-width:961px;
  }
  ${Media.sm} {
    max-width:737px;
  }
  ${Media.xs} {
    max-width: -webkit-fill-available;
  }
`;
 

export default Gs; 

