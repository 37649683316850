import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import Gs from '../theme/globalStyles';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Media from '../theme/media-breackpoint';
import AOS from 'aos';
import 'aos/dist/aos.css';
import S2Img from '../assets/images/s2-img.png';
import S3Img from '../assets/images/s3-img.png';
import S4Img from '../assets/images/s4-img.png';
import S5Img from '../assets/images/s5-img.png';
import S6Img from '../assets/images/s6-img.png';
import S7Img from '../assets/images/s7-img.png';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Home(props) {

  let query = useQuery();

  AOS.init({
    once: true,
    duration: 1500,
  });

  const goToAPP = () => {
    const ref = query.get('ref');
    if(ref !== undefined)
      window.location.href = `https://app.pdo.finance/#/list?ref=${ref}`;
    else
      window.location.href = 'https://app.pdo.finance/#/list';
  }



  return (
    <>
      <Section1>
        <Gs.Container>
          <BigText className='aos-init' data-aos="fade-up">DECENTRALIZED <span>POST</span> DEX OFFERINGS</BigText>
          <BDesc className='aos-init' data-aos="fade-up">New ScalePad model to boost existing projects after launchpad</BDesc>
        </Gs.Container>
      </Section1>
      <Section2>
        <Gs.Container>
          <S2Inner className='ver2'>
            <W50 className='aos-init' data-aos="fade-right">
              <S2Title>New staking model to increase liquidity and token value on any DEX</S2Title>
              <S2Desc>PDO allows projects to offer their tokens for sale with zero slippage and APY staking. Tokens are offered at real time market price with funds raised being allocated to the pool of the project's choice. Therefore, with investment the token and liquidity in the pool go up automatically.</S2Desc>
            </W50>
            <W50 className='img-center aos-init' data-aos="fade-left">
              <img src={S2Img} alt="" />
            </W50>
          </S2Inner>
        </Gs.Container>
      </Section2>
      <Section2>
        <Gs.Container>
          <S2Inner>
            <W50 className='img-center aos-init' data-aos="fade-right">
              <img src={S3Img} alt="" />
            </W50>
            <W50 className='aos-init' data-aos="fade-left">
              <S2Title>Flexible distribution</S2Title>
              <S2Desc>Funds received through a PDO can be split between the project team, Dumper Shield vesting tokens, or the project's liquidity pool on any DEX. Liquidity pools can be on DEREX exchange, Uniswap, Sushiswap, Pancakeswap, or any DEX.</S2Desc>
            </W50>
          </S2Inner>
        </Gs.Container>
      </Section2>
      <Section2>
        <Gs.Container>
          <S2Inner className='ver2'>
            <W50 className='aos-init' data-aos="fade-right">
              <S2Title>New strategic Secure Future staking solution allows users typical staking returns while retaining the project upside</S2Title>
              <S2Desc>If investors are not satisfied with the tokens price performance, they can withdraw their principal at any time before the due date. By utilizing Secure Future, the investor does not lose opportunity cost and receives the full staking rewards on the principal from decentralized protocols such as Compound, Venus, and others.</S2Desc>
            </W50>
            <W50 className='img-center aos-init' data-aos="fade-left">
              <img src={S4Img} alt="" />
            </W50>
          </S2Inner>
        </Gs.Container>
      </Section2>
      <Section2>
        <Gs.Container>
          <S2Inner>
            <W50 className='img-center aos-init' data-aos="fade-right">
              <img src={S5Img} alt="" />
            </W50>
            <W50 className='aos-init' data-aos="fade-left">
              <S2Title>Scale fundraising and liquidity in a BULL and in a BEAR <span>market</span></S2Title>
              <S2Desc>Investors that are apprehensive about investing in a PDO can invest in Secure Future instead, allowing them to automatically secure an APY bonus at the current token price on the market. Additionally, Secure Future allows investors to postpone their principal investment into the PDO process until they are comfortable while still earning staking reward.</S2Desc>
            </W50>
          </S2Inner>
        </Gs.Container>
      </Section2>
      <Section2>
        <Gs.Container>
          <S2Inner className='ver2'>
            <W50 className='aos-init' data-aos="fade-right">
              <S2Title>A new fundraising model that combines the needs of the community and existing projects</S2Title>
              <S2Desc>A common issue among all startups, not only crypto, is 98% of startups eventually fail from the inability to raise further capital. Instead of removing funds from liquidity pools or dumping tokens into the market, PDO helps existing projects increase liquidity, raise new funds for operations, and cash out in a safe way that increases liquidity, the token market value, and total value locked. </S2Desc>
            </W50>
            <W50 className='img-center aos-init' data-aos="fade-left">
              <img src={S6Img} alt="" />
            </W50>
          </S2Inner>
        </Gs.Container>
      </Section2>
      <Section2>
        <Gs.Container>
          <S2Inner>
            <W50 className='img-center aos-init' data-aos="fade-right">
              <img src={S7Img} alt="" />
            </W50>
            <W50 className='aos-init' data-aos="fade-left">
              <S2Title>Dumper shield protection and liquidity access for vesting tokens  </S2Title>
              <S2Desc>The Dumper Shield allows tokens which are vesting to engage with a liquidity gateway or in Decentralized OTC in order to reduce sell pressure when the vesting period ends. </S2Desc>
            </W50>
          </S2Inner>
        </Gs.Container>
      </Section2>
      <Section1 className='ver2'>
        <Gs.Container>
          <LaunchBtn onClick={() => goToAPP()} className='aos-init' data-aos="fade-down">LAUNCH APP</LaunchBtn>
          <BtnTxt  className='aos-init' data-aos="fade-down">Rewards program </BtnTxt>
        </Gs.Container>
      </Section1>
    </>
  )
}

const FlexDiv = styled.div`
  display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;

const Section1 = styled.div`
  text-align:center; width:100%; padding:300px 0px 150px;
  &.ver2{padding:150px 0px;
    ${Media.sm}{
      padding:125px 0px;
    }
    ${Media.xs}{
      padding:50px 0px 100px;
    }
  }
  ${Media.sm}{
    padding:125px 0px;
  }
  ${Media.xs}{
    padding:100px 0px 50px;
  }
`;

const BigText = styled.div`
  font-size:54px; color:${(props) => props.theme.Color1}; font-weight:bold; font-family:'Kanit', Helvetica,sans-serif; margin:0px 0px 10px; text-shadow: 0 0 10px #ffffff99;
  span{ position:relative;
    :after{content:''; width:100%; height:3px; background-color:${(props) => props.theme.BG2}; position:absolute; bottom:10px; left:0;}
  }
  ${Media.lg2}{
    font-size:40px;
  }
  ${Media.xs}{
    font-size:34px;
  }
`;

const BDesc = styled.div`
  font-size:21px; color:${(props) => props.theme.Color2}; font-weight:normal; line-height:30px;
  ${Media.xs}{
    font-size:18px;
  }
`;

const Section2 = styled.div`
  padding:150px 0px; width:100%;
  ${Media.md2}{
    padding:100px 0px;
  }
  ${Media.sm}{
    padding:50px 0px;
  }
`;

const W50 = styled.div`
  width:calc(50% - 30px); margin:0px 15px;
  &.img-center{text-align:center;
    ${Media.sm}{
      margin:0px 0px 30px;
      max-width:80%;
    }
  }
  ${Media.sm}{
    width:100%; margin:0px;
  }
`;

const S2Inner = styled(FlexDiv)`
  &.ver2{
    ${Media.sm}{
      flex-direction:column-reverse;
    }
  }
`;

const S2Title = styled.div`
  font-size:30px; color:${(props) => props.theme.Color1}; line-height:42px; font-weight:bold; font-family:'Kanit', Helvetica,sans-serif; margin:0px 0px 20px; text-transform: uppercase;
  span {position: relative; 
    &:after {content: ''; height: 3px; width: 75px; background: #00f02b; display: table; margin: 0px auto; position: absolute; left: 0; right: 0;}
  }
  ${Media.sm}{
    text-align:center;
  }
  ${Media.xs}{
    font-size:24px; line-height:32px;
  }
`;

const S2Desc = styled.div`
  font-size:18px; color:${(props) => props.theme.Color2}; font-weight:normal; line-height:30px;
  ${Media.sm}{
    text-align:center;
  }
  ${Media.xs}{
    font-size:16px; line-height:26px;
  }
`;

const LaunchBtn = styled.button`
  font-size:24px; font-weight:bold; line-height:18px; color:${(props) => props.theme.Color1}; background-color:${(props) => props.theme.BG2}; width:100%; max-width:500px; height:80px; border-radius:10px; box-shadow: 0 0 10px ${(props) => props.theme.BG2};
  :hover{background-color:${(props) => props.theme.BG2Hover};}
  ${Media.sm}{
    max-width:400px; height:70px;
  }
  ${Media.xs}{
    max-width:100%; font-size:18px;
  }
`;
const BtnTxt = styled.p `
  color: #fff; font-size: 14px; font-weight: 700; margin: 20px 0 0 0;
`

export default Home;