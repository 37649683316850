import React, { useEffect, useState } from 'react'
import Gs from '../theme/globalStyles'
import { Zoom, Fade } from 'react-reveal';
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import LogoImg from '../assets/images/logo.png'


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Header(props) {

  let query = useQuery();
  const goToAPP = () =>{
    const ref = query.get('ref');
    if(ref !== undefined)
      window.location.href = `https://app.pdo.finance/#/list?ref=${ref}`;
    else
      window.location.href = 'https://app.pdo.finance/#/list';
  }

  return (

    <HeaderOuter>
      <img src={LogoImg} alt="PDO.finance" />
      <BtnLink>Rewards program</BtnLink>
      <GreenBtn onClick={()=>goToAPP()}>Launch APP</GreenBtn>
    </HeaderOuter>

  )

}
const FlexDiv = styled.div`
  display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;

const HeaderOuter = styled(FlexDiv)`
  justify-content:space-between; padding: 25px 30px; width:100%;
  ${Media.sm}{
    padding:25px 15px 25px 5px;
  }
  img{
    ${Media.sm}{
      max-width:160px; width:100%;
    } 
    ${Media.xs}{
      max-width:140px;
    }
  }
`;

const GreenBtn = styled.button`
  font-size:14px; font-weight:bold; line-height:18px; color:${(props) => props.theme.Color1}; background-color: inherit; padding:12px 52px 12px 52px; border-radius:10px; box-shadow: 0 0 10px ${(props) => props.theme.BG2}; border: 2px solid ${(props) => props.theme.BG2};
  :hover{background-color:${(props) => props.theme.BG2Hover};}
  ${Media.sm}{
    padding:14px 20px 13px 20px;
  }
  ${Media.xs}{
    font-size: 12px; padding: 6px 10px;
  }
`;

const BtnLink = styled.a`
  color: #fff; margin-left: auto; font-size: 14px; margin-right: 50px;
  ${Media.sm}{
    margin-right: 20px;
  }
  ${Media.xs}{
    font-size: 12px; margin-right: 15px;
  }
`
export default Header;
